import Vue from 'vue'
import App from './App.vue'

import VueRouter from 'vue-router'
import router from '@/router/index.js'

Vue.use(VueRouter)

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import { BootstrapVue } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "bootstrap/scss/bootstrap-grid.scss"

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faPhone, faWhatsapp, faEnvelope, faMapMarkerAlt)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

import './scss/app.scss'

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')