import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/formazione",
    name: "Formazione",
    component: () => import("../views/Formazione.vue"),
  },
  {
    path: "/comelavoro",
    name: "ComeLavoro",
    component: () => import("../views/ComeLavoro.vue"),
  },
  {
    path: "/fisioterapia",
    name: "Fisioterapia",
    component: () => import("../views/Fisioterapia.vue"),
  },
  {
    path: "/osteopatia",
    name: "Osteopatia",
    component: () => import("../views/Osteopatia.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function(to) {
    // if (savedPosition) {
    //   return savedPosition;
    // }
    if (to.hash) {
      window.scrollTo(0, 0);
      // return { el: to.hash, behavior: "smooth" };
    } else {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    }
  },
});

export default router;
