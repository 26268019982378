<template>
  <footer class="footer bg-primary text-light">
    <div
      class="
        d-flex
        flex-column
        justify-content-center
        align-items-start
        h-100
        w-100
        text-light
        p-4
      "
    >
      <strong>
        <div class="font-header mb-3 text-light">Contatti</div>
      </strong>
      <div
        class="d-flex justify-content-start align-items-center font-header mb-2"
      >
        <font-awesome-icon icon="phone" style="margin-right: 0.2em;" />
        <font-awesome-icon
          :icon="['fab', 'whatsapp']"
          style="margin-left: 0.2em;"
        />
        <div class="font-header mx-2">
          <small>+39 380 3520748</small>
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-center font-header mb-2"
      >
        <font-awesome-icon icon="envelope" />

        <div class="font-header mx-2">
          <small>elia.giovinazzo@gmail.com</small>
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-center font-header mb-2"
      >
        <font-awesome-icon icon="map-marker-alt" />
        <div class="d-flex flex-column font-header mx-2">
          <small>Piazza Manin 3/3, Genova</small>
          <small>(Studio Fisioterapia Manin)</small>
        </div>
      </div>
      <div
        class="d-flex justify-content-start align-items-center font-header mb-2"
      >
        <font-awesome-icon icon="map-marker-alt" />
        <div class="font-header mx-2"><small>Via Cantore 34/9, Genova</small></div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped></style>
