<template>
  <b-navbar toggleable="lg" type="dark" variant="dark"
  class="px-2">
    <b-navbar-brand to="/">
      <img src="../assets/logo.svg" class="mx-2" style="height: 2em" />
      <!-- <span class="font-header font-weight-bold text-light px-2">EG</span> -->
    </b-navbar-brand>
    
    <b-navbar-toggle target="nav-collapse" class="mx-2"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item class="font-header px-2" to="/">Home</b-nav-item>
        <b-nav-item class="font-header px-2" to="/formazione">La mia formazione</b-nav-item>
        <b-nav-item class="font-header px-2" to="/comelavoro">Come lavoro</b-nav-item>
        <b-nav-item class="font-header px-2" to="/osteopatia">Osteopatia</b-nav-item>
        <b-nav-item class="font-header px-2" to="/fisioterapia">Fisioterapia</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: "Navbar",
};
</script>

<style scoped>
</style>
