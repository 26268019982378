<template>
  <div class="margin-navbar h-100 w-100">
    <div class="d-flex flex-column w-100 font-body text-dark p-4">
      <h1 class="mt-3 mb-4">
        Studio di Osteopatia e Fisioterapia <br />
        di Elia Giovinazzo
      </h1>
      <h2
        class="d-flex w-100 justify-content-end font-header text-primary mb-4"
      >
        Un approccio specialistico e integrato per migliorare la tua salute e
        ritrovare il tuo benessere.
      </h2>
      <div
        class="img-background w-100 mb-5"
        :style="{ backgroundImage: backgroundImage1 }"
      ></div>
      <h3 class="mb-4">
        Individueremo le cause che stanno dietro ai tuoi problemi e metteremo in
        atto le strategie giuste per contenerli, risolverli e far si che non si
        ripresentino in futuro.
      </h3>
      <p></p>
      <h4 class="mb-3">Osteopata</h4>
      <p>
        Individua le zone del corpo responsabili di una disfunzione, ovvero
        quali parti dell'organismo non cooperano con le altre, dando disturbi e
        disagi quali dolori, problemi digestivi, di sonno, limitazioni di
        movimento e altro.
      </p>
      <div
        class="img-background mb-5"
        :style="{ backgroundImage: backgroundImage2 }"
      ></div>
      <p>
        Attraverso tecniche specifiche l'<router-link to="/osteopatia"
          >osteopata</router-link
        >
        libera il paziente da questi problemi fisici, così da permettere al
        corpo di ritrovare il propio equilibrio ed un corretto funzionamento,
        cioè un buono stato di salute.
      </p>
      <h4 class="mb-3">Fisioterapista</h4>
      <p>
        Attraverso tecniche manuali, terapie fisiche ed esercizi specifici porta
        il paziente a recuperare lo stato fisico ottimale in seguito ad
        infortuni, interventi chirurgici, disordini posturali e dolori in
        generale.
      </p>
      <div
        class="img-background mb-5"
        :style="{ backgroundImage: backgroundImage3 }"
      ></div>
      <p>
        Il <router-link to="/fisioterapia">fisioterapista</router-link> ha a
        cuore anche la prevenzione e l'insegnamento delle condotte più
        vantaggiose affinchè la persona che vi si rivolge impari a mantenersi in
        un buono stato di salute, quindi prevenire ricadute o nuovi disagi.
      </p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

const image1 = require("@/assets/elia-home-cut.jpg");
const image2 = require("@/assets/elia-osteopatia.jpg");
const image3 = require("@/assets/elia-fisioterapia.jpg");

export default {
  name: "Home",
  components: {},
  metaInfo: {
    title: "Elia Giovinazzo - Osteopatia  e Fisioterapia - Genova",
    // override the parent template and just use the above title only
    titleTemplate: null,
    meta: [
      {
        name: "description",
        content:
          "Studio di Osteopatia e Fisioterapia del Dott. Elia Giovinazzo. Professionista della salute a Genova. Riabilitazione, Trattamento e Prevenzione.",
      },
      {
        property: "og:title",
        content: "Elia Giovinazzo - Osteopatia  e Fisioterapia - Genova",
      },
      { property: "og:site_name", content: "Elia Giovinazzo" },
      { property: "og:type", content: "website" },
      { name: "robots", content: "index,follow" },
    ],
  },
  data() {
    return {
      backgroundImage1: 'url("' + image1 + '")',
      backgroundImage2: 'url("' + image2 + '")',
      backgroundImage3: 'url("' + image3 + '")',
    };
  },
};
</script>

<style scoped></style>
