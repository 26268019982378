<template>
  <div id="app" class="d-flex flex-column justify-content-center align-items-center">
    <Navbar class="w-100 position-absolute fixed-top"/>
    <router-view style="max-width: 940px;"/>
    <Footer class="w-100"/>
  </div>
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Footer
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // height: 100vh;
  // max-width: 940px;
  // padding-bottom: 100px;
}

</style>
